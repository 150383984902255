import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import ProgressLoader from './loader/ProgressLoader';
import axios from '../helper/Axios';
import parse from 'html-react-parser';

import hourglass from '../../static/images/hourglass.svg';
import server_error from '../../static/images/server_error.svg';
import image_url_map from '../../static/images/constants/image_constants';
import handleException from '../helper/errorHandling';
const amber_hi = image_url_map['amber_hi'];

export default function WorkplaceInstaller() {

    const [code_param, setCodeParam] = useState(''); //eslint-disable-line no-unused-vars
    const [messages, setMessage] = useState([]);
    const [redirect_uri_fb, setRedirectURI] = useState('https://infeedotest767.workplace.com/work/install_done_redirect/');
    const [loading, setLoading] = useState(false);
    const [show_error, setErrorState] = useState(false);

    const showError = (err_msg) => {
        NotificationManager.error(parse(err_msg));
        setMessage([...messages, err_msg]);
    }

    const requestAccessToken =  async (code) => {
        setMessage([...messages, 'Fetching Access Token...'])
        try {
            const response = await axios.post(`/app_integrations/workplace/install`, {code});
            setMessage([...messages, 'Redirecting....']);
            if(response && response.status < 300){
                setMessage([...messages, 'User Authenticated....']);
                window.location.replace(redirect_uri_fb);
            }
        } catch (error) {
            setMessage([...messages, error.message])
            setErrorState(true);
            handleException(error, 'Something went wrong');
        }
    }


    useEffect(()=>{
        setLoading(true);
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code');
        const redirect_uri = params.get('redirect_uri');
        setRedirectURI(redirect_uri);
        if(!code){
            setErrorState(true);
            showError("Invalid Code Parameter <br> Try again after some time.");
        }else{
            setCodeParam(code)
            requestAccessToken(code);
        }
        setLoading(false);
    },[]); //eslint-disable-line react-hooks/exhaustive-deps
        return (
        <>
            <div className="content whatsapp-content has-text-centered columns pd-top-75">
                {
                    loading ?
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <p className="mg-bottom-10">Please wait while we are processing your request...</p>
                            <ProgressLoader />
                        </div>
                    </div>
                    :
                    <div className="card is-4 is-offset-4 column is-8-mobile is-offset-2-mobile">
                        <div className="card-content">
                            <img width="20%" src={ show_error ? server_error: hourglass} alt = "meta-workplace"/>
                            <p className="is-size-4 mg-bottom-15 has-text-weight-bold">{show_error ? 'Sorry' : 'Current Step'}</p>
                                {
                                    messages.map((msg)=>(
                                        <p>{parse(msg)}</p>
                                    ))
                                }
                        </div>
                    </div>
                }
            </div>
        <img className="amber-hi" src={amber_hi} alt = "amber"/>
        </>
  )
}
