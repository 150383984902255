import React from "react"
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo"
import WorkplaceInstaller from "../components/workplace-opt-in";
//assets
import infeedo_logo from '../../static/images/infeedo-logo.svg';


const WorkplaceLogin = () => (
    <Layout mode={true} extra_class={'blue-content full-height whatsapp'}>
        <SEOComponent title="Amber by inFeedo | Workplce Installation"/>
        <div className="">
            <figure className="logo column mg-bottom-40 has-text-left pd-30">
                <img src={infeedo_logo} alt="logo" className="mg-left-5" />
            </figure>
            <WorkplaceInstaller/>
        </div>
    </Layout>
)

export default WorkplaceLogin